import React, { forwardRef } from 'react';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  large?: boolean;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  [key: string]: any;
  children: any;
}

export const Button = ({
  large,
  className,
  disabled,
  isLoading,
  children,
  ...rest
}: ButtonProps) => (
  <button
    className={`
        bg-primary
        hover:bg-primary/90
        disabled:bg-primary/80
        focus:ring
        text-white
        py-2
        px-4
        rounded-full
        ${large ? 'px-8 text-xl' : ''}
        ${className}
        ${disabled ? 'cursor-not-allowed' : ''}
      `}
    {...rest}
  >
    {isLoading ? (
      <svg
        className="animate-spin h-5 w-5 mx-auto my-0.5 stroke-primary"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ) : (
      children
    )}
  </button>
);
