import { ErrorMessage } from 'formik';

interface Props {
  name: string;
  className?: string;
}

export const FormikFieldError = ({ name, className = '' }: Props) => (
  <div
    className={`
    text-sm leading-none pl-2 mb-4 md:mb-0 text-red-500 
    ${className}`}
  >
    <ErrorMessage name={name} />
  </div>
);
