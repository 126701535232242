import { useField } from 'formik';
import { FormikFieldError } from './FormikFieldError';

interface Props {
  name: string;
  tabIndex: number;
  children: React.ReactNode;
}

export const FormikCheckbox = ({
  name,
  tabIndex,
  children
}: Props): JSX.Element => {
  const [field, meta] = useField({ name, type: 'checkbox' });
  const erroronous = meta.error && meta.touched;

  return (
    <div className="mb-6">
      <div className="flex justify-center">
        <label className="inline-flex items-center relative">
          <input
            className={`
              h-5 w-5
              border
              rounded
              border-gray-500
              appearance-none
              checked:bg-white
              ${erroronous ? 'border-red-500' : ''}
            `}
            type="checkbox"
            tabIndex={tabIndex}
            {...field}
          />

          {/* SVG to show when checkbox is checked */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 hidden fill-primary absolute left-[2px]"
            viewBox="0 0 448 512"
            fill="currentColor"
          >
            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
          </svg>

          <span className="ml-2 text-gray-700">{children}</span>
        </label>
      </div>

      {erroronous && (
        <div className="text-center">
          <FormikFieldError name={name} className="mt-2" />
        </div>
      )}
    </div>
  );
};
