import Link from 'next/link';

interface ButtonLinkProps {
  url: string;
  tabIndex?: number;
  className?: string;
  children: React.ReactNode;
}

export const ButtonLink = ({
  url,
  tabIndex,
  className = '',
  children,
  ...rest
}: ButtonLinkProps) => (
  <Link href={url}>
    <a
      className={`
        underline
        hover:no-underline
        ${className}
      `}
      tabIndex={tabIndex}
      {...rest}
    >
      {children}
    </a>
  </Link>
);
