import { useField } from 'formik';
import { FormikFieldError } from './FormikFieldError';
import { textfieldStyle } from './textfield.style';

interface Props {
  label: React.ReactElement | string;
  name: string;
  type?: string;
  tabIndex: number;
  [key: string]: any;
}
export const FormikTextfield = ({
  label,
  name,
  type = 'text',
  tabIndex,
  ...rest
}: Props): JSX.Element => {
  const [field, meta] = useField(name);
  const erroronous = meta.error && meta.touched;
  const { className, ...restOfProps } = rest;

  return (
    <div className="md:pb-4">
      <label htmlFor={name} className="inline-block font-semibold mb-1">
        {label}
      </label>

      <input
        className={`
          ${textfieldStyle}
          ${className || ''}
          ${erroronous ? 'border-red-500' : ''}
        `}
        id={name}
        type={type}
        tabIndex={tabIndex}
        {...field}
        {...restOfProps}
      />

      {erroronous && <FormikFieldError name={name} className="-mt-2" />}
    </div>
  );
};
