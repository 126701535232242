export const textfieldStyle = `
  block
  w-full
  px-4
  py-2
  mb-4
  text-primary
  bg-white
  border
  rounded-md
  focus:border-purple-400
  focus:ring-purple-300
  focus:outline-none
  focus:ring
  focus:ring-opacity-40
  disabled:bg-gray-100`;
