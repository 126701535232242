import { useField } from 'formik';
import { FormikFieldError } from './FormikFieldError';
import { textfieldStyle } from './textfield.style';

interface Props {
  name: string;
  tabIndex: number;
  [key: string]: any;
}
export const FormikTextarea = ({
  name,
  tabIndex,
  ...rest
}: Props): JSX.Element => {
  const [field, meta] = useField(name);
  const erroronous = meta.error && meta.touched;

  return (
    <div className="md:pb-4">
      <textarea
        className={`
          ${textfieldStyle}
          ${rest.className || ''}
          ${erroronous ? 'border-red-500' : ''}
        `}
        id={name}
        tabIndex={tabIndex}
        {...field}
        {...rest}
      ></textarea>

      {erroronous && <FormikFieldError name={name} className="-mt-2" />}
    </div>
  );
};
