import { useField } from 'formik';
import { cloneElement } from 'react';
import { FormikFieldError } from './FormikFieldError';

interface RadioButtonGroupProps {
  label?: string;
  name: string;
  children: React.ReactElement[] | React.ReactElement;
}

export const FormikRadioButtonGroup = ({
  label,
  name,
  children
}: RadioButtonGroupProps) => {
  const [_, meta] = useField({
    name,
    type: 'radio'
  });
  const erroronous = meta.error && meta.touched;

  return (
    <div className="md:pb-4">
      {label && (
        <label htmlFor={name} className="inline-block font-semibold mb-1">
          {label}
        </label>
      )}

      <div className="flex flex-col justify-center text-left">
        {Array.isArray(children)
          ? children.map((child, index) =>
              cloneElement(child, { name, key: index })
            )
          : cloneElement(children, { name })}
      </div>

      {erroronous && (
        <div className="my-4">
          <FormikFieldError name={name} />
        </div>
      )}
    </div>
  );
};

interface RadionButtonProps {
  name?: string;
  value: string;
  children: React.ReactNode;
}
export const FormikRadioButton = ({
  name,
  value,
  children
}: RadionButtonProps) => {
  const [field] = useField({
    name,
    type: 'radio',
    value
  });

  return (
    <div className="flex mb-2 border rounded hover:bg-blue-100">
      <input
        id={`fld${value}`}
        className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-2 mx-2 cursor-pointer"
        type="radio"
        {...field}
      />
      <label
        htmlFor={`fld${value}`}
        className="flex-1 text-gray-800 py-1 cursor-pointer"
      >
        {children}
      </label>
    </div>
  );
};
