import { useField } from 'formik';
import { FormikFieldError } from './FormikFieldError';
import ReCAPTCHA from 'react-google-recaptcha';
import { useEffect, useRef } from 'react';

interface Props {
  name: string;
  tabIndex: number;
}
export const FormikReCAPTCHA = ({ name, tabIndex }: Props): JSX.Element => {
  const ref = useRef(null);
  const [field, meta, helpers] = useField(name);
  const erroronous = meta.error && meta.touched;
  const handleChange = (value: string) => helpers.setValue(value);

  useEffect(() => {
    // Reset the recaptcha component properly
    // so it can be used again.
    if (field.value === 'XXXXXXXX' && ref.current?.getValue()) {
      helpers.setValue('');
      helpers.setTouched(false);
      helpers.setError(null);
      ref.current.reset();
    }
  }, [field, helpers, ref]);

  return (
    <div className="md:pb-4">
      <ReCAPTCHA
        ref={ref}
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        onChange={handleChange}
        hl="da"
        tabIndex={tabIndex}
      />

      {erroronous && <FormikFieldError name={name} className="mt-2" />}
    </div>
  );
};
